import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import blogStyles from "./blog.module.scss"
import Head from "../components/head"
import HeaderMercearia from "../components/headermercearia"

const DocesBisc = () => {
    const data = useStaticQuery(graphql`
    query{
      allContentfulProduct(filter: {subcategoria: {eq: "doces biscoitos salgados"}}) {
        edges{
          node{
            precounitario
            title
            titleEnglish
            slug
            media {
              fixed(height: 170, width: 170) {
                height
                base64
                src
                srcSet
                width
              }
          }
        }
      }
    } 
  } 
    `)
  
    return (
        <Layout>
          <Head title="Sweets|Cookies|Snacks" />
          <h1>Groceries</h1>
          <HeaderMercearia></HeaderMercearia>
          <ol className={blogStyles.posts}>
            {data.allContentfulProduct.edges.map(edge => {
              return (
                  <li className={blogStyles.post}>
                  <Link to={`/blog/${edge.node.slug}`}>
                    <h2>{edge.node.titleEnglish}</h2>
                    <div class={blogStyles.productimage}>
                      <Img fixed={edge.node.media.fixed}/>
                    </div>
                    <p>USD {edge.node.precounitario}/unit</p>
                  </Link>
                </li>
              )
            })}
          </ol>
        </Layout>
      )
  }

export default DocesBisc